<template>
  <div class="place-page" v-if="this.placeData">

    <div class="buttons">
      <router-link :to="{path: '/place/map'}" class="button-back-empty">
        <span class="fa fa-arrow-left"></span>
      </router-link>
    </div>

    <div>
      <div class="title">
        Attention!
      </div>
      <div class="description">
        Dear Boogy athlete, in order for you not to worry that your account has the necessary amount
        of funds to pay for the training, after scanning for verification purposes, funds
        (240 minutes * {{ this.getPrice(placeData.price) }}{{ this.getCurrencySymbol() }}) will be
        blocked on your card until the end of the session.<br />
        The difference will be returned. <br />
        The minimum cost of the training is 1{{ this.getCurrencySymbol() }}.
      </div>
      <div class="buttons-bottom">
        <div class="pb-3">
          <a href="#" class="btn btn-form btn-agree" @click.prevent="doPlaceCodeScan()">Agree</a>
        </div>
        <div>
          <router-link :to="{path: '/place/map'}" class="btn btn-form white">
            Declined
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {OrderStatusEnum} from "@/modules/order/enums/OrderEnum";

export default {

  data: () => ({
    placeData: null,
    isConfirm: false
  }),

  async mounted() {

    await this.doPlaceCodeCheck();
  },

  methods: {

    async doResponse(mode) {

      if (mode == 'isNeedAuth') {

        this.saveBackRoute('/place/code/scan/' + this.$route.params?.id + '?hash=' + this.$route.query?.hash);
        await this.redirect('/user/login');
        return false;
      }

      if (mode == 'isNeedCard') {

        await this.redirect('/pay/card/info');
        return false;
      }

      return true;
    },

    async doPlaceCodeCheck() {

      let apiHolder = await this.sendApiGet(['api/place/code/check/' + this.$route.params?.id, {
        hash: this.$route.query?.hash
      }]);

      if (!apiHolder.isSuccess())
        return;

      const mode = apiHolder.data?.mode;

      if (!this.doResponse(mode))
        return;

      if (mode == 'isOrder' && apiHolder.data?.orderData)
        return this.doPlaceCodeScan();

      this.placeData = apiHolder.data?.placeData;
    },

    async doPlaceCodeScan() {

      let apiHolder = await this.sendApiGet(['api/place/code/scan/' + this.$route.params?.id, {
        hash: this.$route.query?.hash
      }]);

      if (!apiHolder.isSuccess())
        return;

      const mode = apiHolder.data?.mode;

      if (!this.doResponse(mode))
        return;

      if (mode == 'isOrder' && apiHolder.data?.orderData) {

        if (apiHolder.data.orderData?.status == OrderStatusEnum.open || apiHolder.data.orderData?.status == OrderStatusEnum.stop) {

          await this.doOrderClose(apiHolder.data?.orderData.id);
          return;
        }
      }

      await this.redirect('/order/view/' + apiHolder.data?.orderData?.id);
      return;
    },

    async doOrderClose(orderId) {

      let apiHolder = await this.sendApiPost(['api/order/status/close/' + orderId]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.orderData && apiHolder.data.orderData?.status == OrderStatusEnum.close) {
        await this.redirect('/order/finish');
        return;
      }

      await this.redirect('/order/view/' + apiHolder.data?.orderData?.id);
    },
  }
}
</script>

<style scoped>

.buttons {
  padding-top: 16px;
  padding-left: 15px;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  padding-bottom: 10px;
}

.description {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.buttons-bottom {
  text-align: center;
  padding-bottom: 50px;
  padding-top: 30px;
}

.btn-form {
  margin: 0 5px;
  padding: 25px 35px;
  width: 100%;
  max-width: 500px;
}

.white {
  color: #FFF !important;
  background: #FF007A33 !important;
}

</style>